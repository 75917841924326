import { TranslateResult } from "vue-i18n";

export interface DropdownProps {
    mode?: "click" | "hover";
    nostyle?: boolean;
    open?: boolean;
    onChange?: () => void;
    expand?: "left" | "right";
    width?: string;
    maxHeight?: string;
}

export interface DropdownItemProps {
    label: string | TranslateResult;
    contentRight?: { icon?: string; iconColor?: DropdownItemColors; text?: string; textColor?: DropdownItemColors };
    iconLeft?: string;
    iconLeftColor?: DropdownItemColors;
    checked?: boolean;
    checkbox?: boolean;
    loading?: boolean;
}

export interface DropdownTitleProps {
    icon: string;
    label: string | TranslateResult;
}

export interface DropdownGeneratorProps {
    actions: {
        type: "title" | "separator" | "item";
        item: any;
        title?: DropdownTitleProps;
    }[];
    relatedData?: any;
}

export enum DropdownItemColors {
    white = "white",
    blue = "blue",
    purple = "purple",
    red = "red",
    orange = "orange",
    yellow = "yellow",
    green = "green",
    grey = "grey",
}

export type supporttedEventNames = "click" | "mouseenter" | "mouseleave" | "clickaway";

export type dropdownDirections = "automatic" | "top" | "bottom" | "left" | "right";

export interface Position {
    left: number;
    top: number;
}

export interface TableDropdownProps {
    width?: string;
    open?: boolean;
    height?: string;
    dsComponent?: any;
    nostyle?: boolean;
    maxWidth?: string;
    componentProps?: any;
    triggerText?: string;
    mode?: "click" | "hover";
    triggerTextClass?: string;
    contentTextClass?: string;
    contentTextColor?: string;
    items: TableDropdownItem[];
    direction?: dropdownDirections;
}

export interface TableDropdownItem {
    icon?: string,
    text: string
}